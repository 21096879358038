<div
  #container
  id="menu-container"
  class="position-fixed top-0 w-100"
  [class.mobile-expanded]="!mobileMenuIsCollapsed"
>
  <div #topRow class="row g-0 ps-4 pe-2">
    <!-- Top-left logo -->
    <div class="col-auto d-flex align-items-center">
      <a class="link-like" routerLink="/">
        <img
          id="logo"
          src="assets/media/tech4planet-logo.svg"
          aria-label="Tech4Planet"
        />
      </a>
    </div>
    <div class="col-auto flex-grow-1"></div>

    <!-- Menu items in navbar on desktop devices -->
    <div class="col-auto d-none d-md-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        routerLink="/home"
        routerLinkActive="fw-bold"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n="home-link|home link@@core.header.home-link-desktop"
        >Home</a
      >
    </div>
    <div class="col-auto d-none d-md-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        routerLink="/vision-mission"
        routerLinkActive="fw-bold"
        [routerLinkActiveOptions]="{ exact: true }"
        i18n="
          vision-mission-link|vision-mission
          link@@core.header.vision-mission-link-desktop"
        >Vision / Mission</a
      >
    </div>
    <div class="col-auto d-none d-md-block">
      <a
        class="btn btn-link text-white text-decoration-none link-like p-3"
        href="mailto:info@tech4planet.org"
        i18n="contact-link|contact link@@core.header.contact-link-desktop"
        >Contact Us</a
      >
    </div>

    <!-- Hamburger menu on mobile devices -->
    <div class="col-auto d-md-none">
      <button
        type="button"
        class="btn btn-link text-white text-decoration-none link-like mx-1 fs-3 pe-auto"
        (click)="mobileMenuCollapse.toggle()"
        aria-label="Toggle menu"
        [attr.aria-expanded]="!mobileMenuIsCollapsed"
        aria-controls="mobileMenuCollapse"
      >
        <span *ngIf="mobileMenuIsCollapsed" class="icon-menu"></span>
        <span *ngIf="!mobileMenuIsCollapsed" class="icon-close"></span>
      </button>
    </div>
  </div>
  <!-- Content of the mobile hamburger menu -->
  <div
    #mobileMenuCollapse="ngbCollapse"
    [(ngbCollapse)]="mobileMenuIsCollapsed"
    class="row d-md-none g-0 justify-content-center w-100 pe-auto mb-3"
  >
    <div class="col-auto w-100">
      <div class="row g-0 justify-content-center">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100"
            routerLink="/home"
            routerLinkActive="fw-bold"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            i18n="home-link|home link@@core.header.home-link-mobile"
            >Home</a
          >
        </div>
      </div>
      <div class="row g-0 justify-content-center">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100"
            routerLink="/vision-mission"
            routerLinkActive="fw-bold"
            [routerLinkActiveOptions]="{ exact: true }"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            i18n="
              vision-mission-link|vision-mission
              link@@core.header.vision-mission-link-mobile"
            >Vision / Mission</a
          >
        </div>
      </div>
      <div class="row g-0 justify-content-center">
        <div class="col-auto w-100">
          <a
            class="btn btn-link text-white text-decoration-none link-like w-100"
            href="mailto:info@tech4planet.org"
            (click)="
              mobileMenuIsCollapsed = true; mobileProductsMenuIsCollapsed = true
            "
            i18n="contact-link|contact link@@core.header.contact-link-mobile"
            >Contact Us</a
          >
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Background blocker when mobile menu is active -->
<div
  *ngIf="!mobileMenuIsCollapsed"
  class="position-fixed p-2 top-0 w-100 h-100 d-md-none bg-black opacity-75"
  (click)="mobileMenuCollapse.toggle()"
></div>
