/* istanbul ignore file */
import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { HeaderComponent } from './header/header.component';
import { RouterModule } from '@angular/router';
import { FooterComponent } from './footer/footer.component';
import { GoogleAnalyticsGTagComponent } from './google-analytics-tag/google-analytics-tag.component';

@NgModule(
{
  declarations: [
    HeaderComponent,
    FooterComponent,
    GoogleAnalyticsGTagComponent,
  ],
  imports: [
    CommonModule,
    RouterModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    NgbModule,
  ],
  exports: [
    HeaderComponent,
    FooterComponent,
    GoogleAnalyticsGTagComponent,
  ],
})
export class CoreModule {
  // Make sure the core module gets imported only once at app level
  constructor (@Optional() @SkipSelf() parentModule?: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
