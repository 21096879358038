// Reference: https://stackoverflow.com/a/57404133/11277795

import { isPlatformBrowser } from '@angular/common';
import { Component, ElementRef, Inject, PLATFORM_ID, Renderer2 } from '@angular/core';
import { GoogleAnalyticsService } from 'src/app/shared';

@Component({
  selector: 'google-analytics-gtag',
  template: '',
})
export class GoogleAnalyticsGTagComponent {
  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    private readonly renderer: Renderer2,
    private readonly el: ElementRef,
    private readonly googleAnalyticsService: GoogleAnalyticsService,
  ) {
    // BROWSER
    if (isPlatformBrowser(this.platformId)) {
      const script = this.renderer.createElement('script') as HTMLScriptElement;
      script.src = `https://www.googletagmanager.com/gtag/js?id=${ this.googleAnalyticsService.trackingCode }`;
      script.async = true;
      this.renderer.appendChild(this.el.nativeElement, script);

      const script2 = this.renderer.createElement('script') as HTMLScriptElement;
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      const scriptBody = this.renderer.createText(`
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          dataLayer.push(arguments);
        }
        gtag('js', new Date());

        // gtag('config', '${ this.googleAnalyticsService.trackingCode }'); // done upon router events
      `);
      this.renderer.appendChild(script2, scriptBody);
      this.renderer.appendChild(this.el.nativeElement, script2);
    }
  }
}
