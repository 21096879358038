<div class="container-fluid bg-dark text-white py-3">
  <div class="row g-0">
    <!-- Copyright notice on the left on desktop devices -->
    <div
      class="d-none d-lg-flex col-auto p-2 justify-content-center align-items-center small"
      i18n="copyright|copyright notice@@core.footer.copyright-desktop"
    >
      © 2023 Tech4Planet. All rights reserved
    </div>
    <div class="col-auto flex-grow-1"></div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        href="mailto:info@tech4planet.org"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="contact|contact button@@core.footer.contact"
        >Contact</a
      >
    </div>
    <!--
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/tos"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="tos-link|Terms of service link@@core.footer.tos-link"
        >Terms of Service</a
      >
    </div>
    -->
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/privacy"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="privacy-link|Privacy statement link@@core.footer.privacy-link"
        >Privacy Statement</a
      >
    </div>
    <div
      class="col-12 col-lg-auto d-flex justify-content-center align-items-center"
    >
      <a
        routerLink="/legal"
        class="btn btn-link text-white text-decoration-none link-like p-2"
        i18n="legal-link|legal notice link@@core.footer.legal-link"
        >Legal</a
      >
    </div>
    <!-- Copyright notice on the bottom on mobile devices -->
    <div
      class="d-flex d-lg-none col-12 p-2 justify-content-center align-items-center small pt-4"
      i18n="copyright|copyright notice@@core.footer.copyright-mobile"
    >
      © 2023 Tech4Planet. All rights reserved
    </div>
  </div>
</div>
