import { isPlatformBrowser } from '@angular/common';
import { AfterViewInit, Component, Inject, PLATFORM_ID } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AutoUnsubscribe } from '@aposphere/ng-aposphere';
import { filter, Subscription } from 'rxjs';
import { GoogleAnalyticsService } from './shared/services';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
@AutoUnsubscribe()
export class AppComponent implements AfterViewInit {

  subscriptions = new Subscription();

  constructor(
    // eslint-disable-next-line @typescript-eslint/ban-types
    @Inject(PLATFORM_ID) private readonly platformId: Object,
    public router: Router,
    public googleAnalyticsService: GoogleAnalyticsService,
  ) {

  }

  ngAfterViewInit(): void {
    // navigate to true domain
    const aliases = ['nature-sphere.org', 'wildsphere.org'];
    const redirectTo = 'tech4planet.org';
    if (isPlatformBrowser(this.platformId) && aliases.includes(window.location.hostname)) window.location.hostname = redirectTo;

    this.subscriptions.add(
      this.router.events.pipe(
        filter(event => event instanceof NavigationEnd),
      ).subscribe(event => {
          if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd) {
            gtag('config', this.googleAnalyticsService.trackingCode, { 'page_path': event.urlAfterRedirects });
          }
        },
      ),
    );
  }
}
