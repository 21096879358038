import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full',
  },
  {
    path: 'home',
    loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'vision-mission',
    loadChildren: () => import('./modules/vision-mission/vision-mission.module').then(m => m.VisionMissionModule),
  },
  {
    path: 'privacy',
    loadChildren: () => import('./modules/privacy-statement/privacy-statement.module').then(m => m.PrivacyStatementModule),
  },

  {
    path: 'legal',
    loadChildren: () => import('./modules/legal-notice/legal-notice.module').then(m => m.LegalNoticeModule),
  },



  {
    path: '**',
    loadChildren: () => import('./modules/page-not-found/page-not-found.module').then(m => m.PageNotFoundModule),
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    anchorScrolling: 'enabled',
    scrollPositionRestoration: 'enabled',
    scrollOffset: [0, 60],
    initialNavigation: 'enabledBlocking',
}),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {
}
