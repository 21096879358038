// Reference: https://matcha.fyi/google-analytics-angular/

import { Injectable } from '@angular/core';

// eslint-disable-next-line @typescript-eslint/ban-types
declare let gtag: Function;

@Injectable({
  providedIn: 'root',
})
export class GoogleAnalyticsService {

  public readonly trackingCode = 'G-YPLDV8EK97';

  /** Trigger an event according to https://developers.google.com/analytics/devguides/collection/gtagjs/events */
  public eventEmitter(
    eventName: string,
    eventCategory: string,
    eventAction: string,
    eventLabel: string | null = null,
    eventValue: number | null = null,
  ) {
    gtag('event', eventName, { eventCategory, eventLabel, eventAction, eventValue });
  }
}
